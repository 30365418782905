@import
"src/main/scss/mixins/media-query",
"src/main/scss/mixins/state-indicator",
"src/main/scss/functions/find-color-invert";

.tabs {
  ::-webkit-scrollbar {
    display: none;
  }

  &__bar {
    //background: var(--gray-1);
  }

  &__scroller {
    position: relative;
    display: flex;
  }

  &__tab {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    margin: 0;
    padding: 0;
    border: 0;
    height: auto;
    //background-color: var(--color-bg);
    padding: var(--space-md) 0;
    color: var(--color-text-subtle);
    flex: 1 1 100%;
    outline: 0;
    transition: all .2s ease;
    @include mq(lg) {
      flex-wrap: nowrap;
    }

    &:not(.is--active) {
      &:hover {
        cursor: pointer;
        color: var(--color-text);
        background-color: rgba(0,0,0,.05);
        //box-shadow: inset 0px -3px 0px 0px  rgba(0,0,0,.2);
      }

      &:active {
        color: var(--color-link);
      }
    }

    &.is {
      &--active {
        pointer-events: none;
        opacity: 1;
        color: var(--color-link);
        background-color: var(--color-bg-light);

        //box-shadow: inset 0px -3px 0px 0px var(--color-link);
      }
    }
  }

  &__text {
    display: block;
    width: 100%;
    font-size: var(--text-xxs);
    letter-spacing: .08em;
    text-transform: uppercase;
    font-weight: var(--type-weight-heading);
    margin-top: var(--space-md);
    @include mq(lg) {
      width: auto;
      margin: 0;
      padding: 0 var(--space-sm);
    }
  }

  &__indicator {
    display: block;
    position: absolute;
    background: var(--color-link);
    bottom: 0;
    height: 3px;
    width: 50px;
    transition: transform .25s ease;
    will-change: transform;
  }

  &__content {
    // background: var(--color-primary);
    // color: #fff;
    padding: var(--space-md);
  }

  &__panel {
    outline: 0;

    // Only active tab
    &:not([hidden]) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}

.tabs {
  &.is {
    /*
    Default tabs
    */
    &--default {
      .tabs {
        &__tab {
          flex-direction: column;
          @include mq(lg) {
            flex-direction: row;
          }
        }

        &__icon {
          font-size: var(--text-md);
          @include mq(lg) {
            margin-right: var(--space-xs);
            font-size: inherit;
          }
        }

        &__text {
          @include mq(lg) {
            display: block;
            text-align: left;
          }
        }
      }
    }

    /*
    Overflow scroll tabs
    */
    &--scroller {
      .tabs {
        &__scroller {
          position: relative;
          white-space: nowrap;
          overflow: auto;
          @include mq(lg) {
            overflow: hidden;
          }
        }

        &__icon {
          margin-right: var(--space-xs);
        }

        &__tab {
          white-space: nowrap;
          overflow: hidden;
          flex: 1 0 auto;
          padding: 0 var(--space-xs);

          &.is {
            &--active {
              box-shadow: inset 0px -3px 0px 0px rgba(0,0,0,.2);
            }
          }

        }
      }
    }
  }
}
